import React, { Component } from 'react';
import './blog.css';

export default class Blog extends Component {

  render() {

    return(
      <div className='blogPage'>
        No Posts Yet
      </div>
    );

  }

}

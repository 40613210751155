import React, { Component } from 'react';
import './sending.css';

export default class Sending extends Component {

  render() {
    return(
      <div className='sendingLogo'>
        <span className='sending_bar'/>
        <span className='sending_bar'/>
        <span className='sending_bar'/>
        <span className='sending_bar'/>
        <span className='sending_bar'/>
        <span className='sending_bar'/>
      </div>
    )
  }

}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './about.css';
//import Hover from './hover/hover';
import GlitchEffect from 'react-glitch-effect';

/*

  About page content:
    * Title (My name)

    * Who I am
    * What I specialize in
    * Goals

*/

export default class About extends Component {

  render() {

    return(
      <div className='aboutPage'>

        <div className='aboutHeader'>

          <h1><GlitchEffect style={{ display: 'inherit' }}>ABOUT ME</GlitchEffect></h1>

          <p>Full Stack Web Development and research programmer</p>

        </div>

        <div className='paragraph'>

          <div className='outerBoxes'></div>

          <div className='inner'>
            Hi, <br/><br/>

            Programming is one of the most valuable skills you can attain with very little resources. All you need is a decent computer,
            internet access, and sometimes a phone. Most of the time, that's all I use to build my websites, APIs, and mobile applications.
            Doing a lot, with very little has become sort of an art to me because I want to see just how far I can go.

            <br/><br/>

            This journey has led me down a path to numerous languages and libraries for machine learning, 3D graphics, and of course, web
            development. Web Development was my entry point into programming/scripting and helped build the foundational skill set for all
            other languages and concepts that I would learn in the future. From there, I learned about 3D graphics, starting with <a href='https://threejs.org/' target='_blank'>
              THREE.JS
            </a>. This taught me about the basic components necessary to render 3D elements to a user's screen. I was able to apply this
            knowledge into my projects that used <a href='https://github.com/jeromeetienne/AR.js/blob/master/README.md' target='_blank'>
              AR.JS
            </a>. Today I'm challenging myself to write my own neural networks and 3D graphics engines from scratch so that I can combine
            the 2 to create unique & high performance augmented reality experiences. The journey to augmented reality is much more difficult
            than my journey into web development, but I love to challenge myself. Lately I've been reading books such as "
              <a href='https://smile.amazon.com/Real-Time-Rendering-Fourth-Tomas-Akenine-M%C3%B6ller/dp/1138627003?tag=realtimerenderin' target='_blank'>
                Real-Time Rendering, Fourth Edition
              </a>
            " and "
              <a href='https://www.oreilly.com/library/view/hands-on-machine-learning/9781492032632/' target='_blank'>
                Hands-On Machine Learning with Scikit-Learn, Keras, and TensorFlow, 2nd Edition
              </a>
            ". I've already learned so much from these 2 books alone, but I'm looking forward to all the things that I may learn when applying
            the skills into future projects. If you have any good book recommendations, or want to work with me, feel free to <Link to='contact'>contact me!</Link>

            <div className='signature'>
              Tino Fileccia
            </div>

          </div>

          <div className='outerBoxes'></div>

        </div>

      </div>
    );

  }

}

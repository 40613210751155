import React, { Component } from 'react';
import './NotFound.css';

export default class NotFound extends Component {

  render() {

    return(
      <div className='notFound'>
        404 not found
      </div>
    );

  }

}
